import {Box, Flex} from '@indoqa/style-system'
import {useQueryParam} from '@oegbv/ui-shared'
import * as React from 'react'
import {useHistory} from 'react-router'
import {ajax} from 'rxjs/ajax'
import {Button} from '../../common/components/button/Button'
import {Table} from '../../common/components/table/Table'
import {cellNoWrap, cellWidth, formatDateTime} from '../../common/components/table/table.utils'
import {TableRow} from '../../common/components/table/TableRow'
import {kvDocsetsPath} from '../kv.paths'
import {loadDocsets} from '../state/kv.services'
import {Docsets} from '../state/kv.types'

export const KvDocsetsPage = () => {
  const [docsets, setDocsets] = React.useState<Docsets | null>(null)
  const history = useHistory()
  const processingState = useQueryParam('ps')

  React.useEffect(() => {
    loadDocsets(ajax, processingState).subscribe((docsets) => {
      setDocsets(docsets)
    })
  }, [processingState])

  const renderRows = () => {
    if (!docsets || !docsets.infos) {
      return null
    }
    return docsets.infos.map((docset, index) => {
      const cellValues = [
        docset.id,
        docset.name,
        formatDateTime(docset.lastContentUpdate),
        docset.lohndatenstatus || '-',
      ]
      const cellFormats = [[cellNoWrap(), cellWidth(30)], [cellWidth(500)], [cellWidth(100)], [cellWidth(50)]]
      return <TableRow key={index} index={index} cellValues={cellValues} cellFormats={cellFormats} />
    })
  }

  const headers = ['Docset-ID', 'Name', 'Last update', 'State']
  return (
    <Box fullWidth fullHeight>
      <Flex p={2} grow={0}>
        <Button name="All" selected={processingState === undefined} onClick={() => history.push(kvDocsetsPath())} />
        <Button
          name="Basic"
          selected={processingState === 'BASIC'}
          onClick={() => history.push(kvDocsetsPath('BASIC'))}
        />
        <Button
          name="Extended"
          selected={processingState === 'EXTENDED'}
          onClick={() => history.push(kvDocsetsPath('EXTENDED'))}
        />
        <Button name="Full" selected={processingState === 'FULL'} onClick={() => history.push(kvDocsetsPath('FULL'))} />
      </Flex>
      <Table headers={headers}>{renderRows()}</Table>
    </Box>
  )
}
