import {Box} from '@indoqa/style-system'
import * as React from 'react'

const getFormattedDateTime = () => {
  const now = new Date()
  const year = now.getFullYear()
  const month = String(now.getMonth() + 1).padStart(2, '0')
  const day = String(now.getDate()).padStart(2, '0')
  const hours = String(now.getHours()).padStart(2, '0')
  const minutes = String(now.getMinutes()).padStart(2, '0')
  return `${year}${month}${day}-${hours}${minutes}`
}

export const KvMindestlohnPage = () => {
  const filename = `mindestlohn-export-${getFormattedDateTime()}.xlsx`
  console.log('filename', filename)
  return (
    <Box p={4}>
      <h3>KV Mindestlöhne</h3>
      <Box pt={2}>
        <a download={filename} href={`/api/v1/kv/mindestlohn-export`}>
          Download
        </a>
      </Box>
    </Box>
  )
}
