export const BASE_PATH = '/kv'

export const KV_REQUEST_PARAMS = {
  processingState: 'ps',
}

export const kvValidationPath = () => `${BASE_PATH}/validation`
export const kvMergeTablesPath = () => `${BASE_PATH}/merge-tables`
export const kvUsageStatisticsPath = () => `${BASE_PATH}/usage-statistics`

export const kvDocsetsPath = (filter?: string) => {
  const queryParams = filter ? `?${KV_REQUEST_PARAMS.processingState}=${filter}` : ''
  return `${BASE_PATH}/docsets${queryParams}`
}

export const kvMindestlohnPath = () => {
  return `${BASE_PATH}/mindestlohn`
}
