import {Observable} from 'rxjs'
import {AjaxCreationMethod} from 'rxjs/internal/observable/dom/AjaxObservable'
import {map} from 'rxjs/operators'
import {getHeaders} from '../../app/service-utils'
import {Docsets, KvMergeTablesInput, KvMergeTablesResult, KvValidationResult} from './kv.types'

const BASE_PATH = '/api/v1/kv'

export const validateFragment = (ajax: AjaxCreationMethod, input: string): Observable<KvValidationResult> => {
  const headers = {
    ...getHeaders(),
    'Content-Type': 'text/plain',
  }
  return ajax
    .post(`${BASE_PATH}/editing-tools/validate-fragment`, input, headers)
    .pipe(map((result) => result.response))
}

export const mergeTables = (ajax: AjaxCreationMethod, input: KvMergeTablesInput): Observable<KvMergeTablesResult> => {
  const headers = {
    ...getHeaders(),
    'Content-Type': 'application/json',
  }
  return ajax.post(`${BASE_PATH}/editing-tools/merge-tables`, input, headers).pipe(map((result) => result.response))
}

export const loadDocsets = (ajax: AjaxCreationMethod, lohndatenstatusFilter?: string | null): Observable<Docsets> => {
  const lohndatenstatusFilterQueryParam = lohndatenstatusFilter ? `&lohndatenstatus=${lohndatenstatusFilter}` : ''
  return ajax.getJSON(`${BASE_PATH}/docset-infos?language=de&sort=name${lohndatenstatusFilterQueryParam}`, getHeaders())
}
